<template>
  <Account title="My activity">
    <AccountHistoryTable depth="user" />
  </Account>
</template>

<script>
export default {
  name: 'AccountActivityUser',
  components: {
    Account: () => import('./Account'),
    AccountHistoryTable: () =>
      import('../components/history/AccountHistoryTable')
  }
}
</script>
